import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import omit from 'lodash.omit';
import pick from 'lodash.pick';

import { EditOrg, EditOrgSuccess, ErrorStatus, PopoutPanelIconHeading } from '../components';
import { types as popoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import ScrollContainer from '../components/ScrollContainer/ScrollContainer';
import SidePanel from '../components/SidePanel/SidePanel';
import { isAdminOrSupport as isAdminOrSupportSelector } from '../redux/selectors/authorization/user.js';

import { isOptMode, isOteMode, isCesMode } from '../utils/platform';
import { allEnabledOrgRoles } from '../globals/orgRoles';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';

class EditOrgPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidUpdate(prevProps) {
    const { orgId, opened, getOrgDetails } = this.props;
    if (!prevProps.opened && opened) {
      getOrgDetails(orgId);
    }
  }

  _getInputs = () => {
    const { data } = this.state;

    return {
      ...pick(this.props, [
        'name',
        'role',
        'countryCode',
        'orgRegion',
        'orgPostcode',
        'sapNumber',
        'curriculumType',
        'orgUniqueId',
        'primaryEmail',
        'webAddress',
        'isLmsLtiTool'
      ]),
      ...data
    };
  };

  _createChangeHandler = name => async value => {
    const { data } = this.state;
    await this.setState({ data: { ...data, [name]: value } });
  };

  _createBlurHandler = name => () => {
    const {
      state: { data },
      props: { validate }
    } = this;
    validate(pick(data, [name]));

    if (name === 'countryCode') {
      this.setOrgLocationOnlyForUK();
    }
  };

  setOrgLocationOnlyForUK = async () => {
    const { countryCode } = this.state;
    if (countryCode !== 'GB') {
      const { data } = this.state;
      await this.setState({ data: { ...data, orgRegion: '', orgPostcode: '' } });
    }
  };

  _submit = () => {
    const { orgId, edit } = this.props;
    edit(orgId, omit(this._getInputs(), ['orgUniqueId']));
  };

  _goBackToForm = () => {
    const { orgId, getOrgDetails, reset } = this.props;
    reset();
    getOrgDetails(orgId);
  };

  _close = () => {
    const { reset, close } = this.props;
    this.setState({ data: {} });
    close();
    reset();
  };

  render() {
    const {
      localizedContent: { editOrgPanel: content, countryCodes: countryCodesContent },
      orgId,
      errors,
      loading,
      submitting,
      success,
      failure,
      opened,
      clearFailure,
      lmsLtiTools,
      isAdminOrSupport
    } = this.props;

    const {
      name,
      role,
      countryCode,
      orgRegion,
      orgPostcode,
      sapNumber,
      curriculumType,
      orgUniqueId,
      primaryEmail,
      webAddress,
      isLmsLtiTool = lmsLtiTools === 0
    } = this._getInputs();

    const disabled = isOteMode() ? ['curriculumType', 'orgUniqueId', 'name'] : ['curriculumType', 'orgUniqueId'];
    const hidden = isOptMode()
      ? ['adminUser.email', 'role', 'webAddress']
      : [
          'adminUser.email',
          'role',
          'address',
          'city',
          'taxRegistration',
          'province',
          'department',
          'hearAboutUs',
          'webAddress',
          ...(lmsLtiTools !== 0 ? ['isLmsLtiTool'] : [])
        ];

    if (!isCesMode() || !isAdminOrSupport) {
      hidden.push('orgRegion', 'orgPostcode', 'sapNumber');
    }

    return (
      <SidePanel id="editOrgPanel" ariaLabel={content.title} isOpen={opened} onClose={this._close}>
        {(() => {
          switch (true) {
            case loading:
            case submitting:
              return (
                <ScrollContainer>
                  <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} title={content.waiting_message} />
                </ScrollContainer>
              );
            case success:
              return (
                <EditOrgSuccess
                  name={name}
                  orgUniqueId={orgId}
                  primaryButtonLabel={content.done_button}
                  secondaryButtonLabel={content.back_button}
                  onPrimaryButtonClick={this._close}
                  onSecondaryButtonClick={this._goBackToForm}
                />
              );
            case failure:
              return (
                <ScrollContainer>
                  <ErrorStatus
                    title={content.error_title}
                    buttonText={content.error_button}
                    buttonOnClickHandler={clearFailure}
                  />
                </ScrollContainer>
              );
            default:
              return (
                <EditOrg
                  roles={allEnabledOrgRoles}
                  countries={countryCodesContent}
                  name={name}
                  role={role}
                  countryCode={countryCode}
                  orgRegion={orgRegion}
                  orgPostcode={orgPostcode}
                  sapNumber={sapNumber}
                  curriculumType={curriculumType}
                  orgUniqueId={orgUniqueId}
                  primaryEmail={primaryEmail}
                  webAddress={webAddress}
                  disabled={disabled}
                  hidden={hidden}
                  errors={errors}
                  isLmsLtiTool={isLmsLtiTool}
                  createChangeHandler={this._createChangeHandler}
                  createBlurHandler={this._createBlurHandler}
                  onSubmit={this._submit}
                />
              );
          }
        })()}
      </SidePanel>
    );
  }
}
EditOrgPanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  lmsLtiTools: PropTypes.number,
  isAdminOrSupport: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  opened: PropTypes.bool.isRequired,
  getOrgDetails: PropTypes.func.isRequired,
  clearFailure: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('editOrgPanel', 'countryCodes'),
  connect(
    state => ({
      ...pick(state.editOrg, [
        'name',
        'role',
        'countryCode',
        'orgRegion',
        'orgPostcode',
        'sapNumber',
        'curriculumType',
        'primaryEmail',
        'webAddress',
        'lmsLtiTools',
        'errors',
        'loading',
        'submitting',
        'success',
        'failure'
      ]),
      orgId: state.organisationPage.orgId || state.identity.currentOrganisationId,
      orgUniqueId: state.editOrg.orgUniqueId || '',
      isAdminOrSupport: isAdminOrSupportSelector(state)
    }),
    dispatch => ({
      getOrgDetails: id => {
        dispatch(actions.editOrgGetDetailsRequest(id));
      },
      clearFailure: () => {
        dispatch(actions.editOrgClearFailure());
      },
      validate: input => {
        dispatch(actions.editOrgValidateInput(input));
      },
      edit: (id, input) => {
        dispatch(actions.editOrgRequest(id, input));
      },
      reset: () => {
        dispatch(actions.editOrgReset());
      }
    })
  )
)(EditOrgPanel);
