import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import style from './StudentReport.scss';
import StudentsReportLoader from '../SkeletonLoader/Orb/StudentsReportLoader';
import withLocalizedContent from '../../language/withLocalizedContent';
import { fgClass } from '../../utils/colorClassNames';
import colors from '../../globals/colors';

import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import PillTabs from '../PillTabs/PillTabs';
import AttainmentDetails from '../AttainmentDetails/AttainmentDetails';
import EngagementDetails from '../EngagementDetails/EngagementDetails';
import ProgressDetails from '../ProgressDetails/ProgressDetails';
import StudentReportCard from '../StudentReportCard/StudentReportCard';
import StudentProgress from '../StudentProgress/StudentProgress';

momentDurationFormatSetup(moment);

const SKILLS_ORDER = [
  'Finding information',
  'Making connections and inferences',
  'Understanding vocabulary',
  'Using structure and organization to make meaning',
  "Understanding and appreciating author's toolkit"
];

const percentagePropType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);
const skillsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    result: PropTypes.shape({
      percentage: percentagePropType.isRequired
    }).isRequired,
    answer: PropTypes.number
  })
);

function formatPercentage(value) {
  return value === 'N/A' ? value : numeral(value / 100).format('%');
}

function formatSkillsPercentage(skills) {
  const sortedSkills = skills.sort((a, b) => SKILLS_ORDER.indexOf(a.name) - SKILLS_ORDER.indexOf(b.name));
  return sortedSkills.map(({ result, ...others }) => ({
    ...others,
    percentage: formatPercentage(result.percentage)
  }));
}

class StudentReport extends Component {
  static printReport = () => {
    window.print();
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    };
  }

  componentDidMount() {
    this.getReport();
  }

  componentDidUpdate(prevProps) {
    const { orgId = '', studentId = '', locationId = '' } = this.props;
    if (prevProps.orgId !== orgId || prevProps.studentId !== studentId || prevProps.locationId !== locationId) {
      this.getReport();
    }
  }

  getReport() {
    const { orgId = '', studentId = '', locationId = '', getStudentReport } = this.props;
    if (!orgId || !studentId || !locationId) {
      console.warn('Org, student or location is missing');
      return;
    }

    getStudentReport(orgId, studentId, locationId);
  }

  openQuiz = (type, id) => {
    const { studentId, openQuiz: openQuiz1 } = this.props;
    openQuiz1(type, id, studentId);
  };

  renderPrintReportName(reportName) {
    const { studentName } = this.props;

    return (
      <div className="only-print">
        <SVGIcon
          glyph={GLYPHS.ICON_USER}
          className={classnames('glyphFormating', {
            [fgClass(colors.LEARNER)]: colors.LEARNER
          })}
        />
        {reportName}
        {studentName && <h2>{studentName}</h2>}
      </div>
    );
  }

  renderOverviewTab() {
    const {
      locationId,
      attainmentReport,
      progressReport,
      engagementReport,
      localizedContent: { studentReportComponent: content }
    } = this.props;

    if (attainmentReport.error || progressReport.error || engagementReport.error) {
      return (
        <div>
          <p className="error-paragraph">{content.overview_tab_error}</p>
        </div>
      );
    }

    const attainmentTableHeader = [
      { key: 'name', label: content.badges_received },
      { key: 'date', label: content.date },
      { key: 'count', label: content.number_of_badges }
    ];

    const progressTableHeader = [
      { key: 'name', label: content.skill_category },
      { key: 'percentage', label: content.quiz_performance },
      { key: 'answered', label: content.question_answered }
    ];

    const engagementTableHeader = [
      { key: 'name', label: content.buddy_questions_performance_by_skill },
      { key: 'percentage', label: content.quiz_performance },
      { key: 'answered', label: content.question_answered }
    ];

    return (
      <div className={style.tabWrapper}>
        <a
          className={style.printLinkStudents}
          role="link"
          onClick={StudentReport.printReport}
          onKeyDown={StudentReport.printReport}
          tabIndex={0}
        >
          {content.print_report}
        </a>
        {this.renderPrintReportName(content.overview_report_name)}
        {attainmentReport.success ? (
          <StudentReportCard
            title={['ae', 'cn'].includes(locationId.toLowerCase()) ? content.achievement : content.attainment}
            type="attainment"
            status={attainmentReport.status}
            className={style.reportCard}
            detailReport={
              <AttainmentDetails
                levelGroup={attainmentReport.levelGroup}
                currentLevel={attainmentReport.levelName}
                expectedLevel={attainmentReport.expected}
                forceRowDisplay
              />
            }
            tableHeaders={attainmentTableHeader}
            tableRows={(attainmentReport.badges || []).map(({ date, ...others }) => ({
              ...others,
              date: moment(date).format('DD/MM/YYYY')
            }))}
            tableEmptyMessage={content.attainment_empty_table_message}
          />
        ) : null}

        {progressReport.success ? (
          <StudentReportCard
            title={content.progress}
            type="progress"
            status={progressReport.status}
            className={style.reportCard}
            detailReport={
              <ProgressDetails
                days_at_level={progressReport.days.count}
                expected_days_at_level={progressReport.days.expected}
                progress_status={progressReport.status}
                quizzes_completed={progressReport.quizzes.completed}
                quizzes_pass_score={formatPercentage(progressReport.quizzes.passScore.percentage)}
                quizzes_expected={progressReport.quizzes.expected}
                quizzes_passed={progressReport.quizzes.passScore.passed}
                quizzes_average={formatPercentage(progressReport.quizzes.average.percentage)}
                hideProgressText
                forceRowDisplay
              />
            }
            tableHeaders={progressTableHeader}
            tableRows={formatSkillsPercentage(progressReport.skills)}
          />
        ) : null}

        {engagementReport.success ? (
          <StudentReportCard
            title={content.engagement}
            type="engagement"
            status={engagementReport.status}
            className={style.reportCard}
            detailReport={
              <EngagementDetails
                lastLogin={
                  moment(engagementReport.sessions.last).isValid()
                    ? moment(engagementReport.sessions.last).fromNow()
                    : content.not_yet_logged_in
                }
                bookReadAtCurrentLevel={engagementReport.books.level.read}
                bookReadThisYear={engagementReport.books.year.read}
                forceRowDisplay
              />
            }
            coachingBookData={{
              readCount: engagementReport.books.coaching.read,
              totalCount: engagementReport.books.coaching.max,
              clickedPercentage: formatPercentage(engagementReport.buddy.clicked.percentage),
              scorePercentage: formatPercentage(engagementReport.buddy.results.percentage)
            }}
            tableHeaders={engagementTableHeader}
            tableRows={formatSkillsPercentage(engagementReport.skills)}
          />
        ) : null}
      </div>
    );
  }

  renderProgressTab() {
    const {
      progressItems,
      localizedContent: { studentReportComponent: content }
    } = this.props;

    if (progressItems.error) {
      return (
        <div>
          <p className="error-paragraph">{content.progress_items_tab_error}</p>
        </div>
      );
    }

    return progressItems.success ? (
      <div className={style.tabWrapper}>
        {this.renderPrintReportName(content.progress_items_report_name)}
        <a
          className={style.printLinkStudents}
          role="link"
          onClick={StudentReport.printReport}
          onKeyDown={StudentReport.printReport}
          tabIndex={0}
        >
          {content.print_reading_report}
        </a>
        <StudentProgress items={progressItems.items} openQuiz={this.openQuiz} />
      </div>
    ) : null;
  }

  render() {
    const {
      studentId,
      localizedContent: { studentReportComponent: content },
      attainmentReport,
      progressReport,
      engagementReport,
      progressItems
    } = this.props;

    if (!studentId || studentId === 'undefined') {
      return (
        <PopoutPanelIconHeading
          type={types[types.WARNING]}
          title="Something went wrong."
          subtitle="Unable to fully retrieve student information at this time."
        />
      );
    }

    if (
      attainmentReport.submitting ||
      progressReport.submitting ||
      engagementReport.submitting ||
      progressItems.submitting
    ) {
      return (
        <StudentsReportLoader
          speed={2}
          foregroundColor={colors.COLOR_GREY_DISABLED}
          backgroundColor={colors.COLOR_WHITE}
        />
      );
    }

    const { currentTab } = this.state;
    return (
      <PillTabs
        current={currentTab}
        items={[
          {
            action: () => this.setState({ currentTab: 0 }),
            tabText: content.overview_tab_name,
            panelContent: this.renderOverviewTab()
          },
          {
            action: () => this.setState({ currentTab: 1 }),
            tabText: content.progress_items_tab_name,
            panelContent: this.renderProgressTab()
          }
        ]}
      />
    );
  }
}

StudentReport.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  studentId: PropTypes.string,
  studentName: PropTypes.string,
  locationId: PropTypes.string,
  attainmentReport: PropTypes.shape({
    status: PropTypes.string,
    levelGroup: PropTypes.string,
    levelName: PropTypes.string,
    expected: PropTypes.string,
    badges: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired
      })
    ),
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool
  }).isRequired,
  progressReport: PropTypes.shape({
    status: PropTypes.string,
    days: PropTypes.shape({
      count: PropTypes.number.isRequired,
      expected: PropTypes.number.isRequired
    }).isRequired,
    quizzes: PropTypes.shape({
      passScore: PropTypes.shape({
        percentage: percentagePropType.isRequired,
        passed: PropTypes.number.isRequired
      }).isRequired,
      average: PropTypes.shape({ percentage: percentagePropType.isRequired }),
      expected: PropTypes.number.isRequired,
      completed: PropTypes.number.isRequired
    }),
    skills: skillsPropType,
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool
  }).isRequired,
  engagementReport: PropTypes.shape({
    status: PropTypes.string,
    sessions: PropTypes.shape({
      last: PropTypes.string.isRequired,
      count: PropTypes.object.isRequired
    }),
    books: PropTypes.shape({
      level: PropTypes.shape({
        read: PropTypes.number.isRequired
      }).isRequired,
      coaching: PropTypes.shape({
        max: PropTypes.number.isRequired,
        read: PropTypes.number.isRequired
      }).isRequired,
      year: PropTypes.shape({ read: PropTypes.number.isRequired }).isRequired
    }),
    skills: skillsPropType,
    buddy: PropTypes.shape({
      clicked: PropTypes.shape({ percentage: percentagePropType }).isRequired,
      results: PropTypes.shape({ percentage: percentagePropType }).isRequired
    }),
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool
  }).isRequired,
  progressItems: PropTypes.shape({
    items: PropTypes.array.isRequired,
    submitting: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.bool
  }).isRequired,
  getStudentReport: PropTypes.func.isRequired,
  openQuiz: PropTypes.func.isRequired
};

export default withLocalizedContent('studentReportComponent')(StudentReport);
