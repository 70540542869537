import signedFetch from './util/signedFetch.js';

export default (orgId, body) => {
  const formData = new FormData();
  const blob = new Blob([body.fileContents], { type: body.fileType });
  formData.append('fileContents', blob, body.fileName);

  return signedFetch(
    'bulkUsersUpload',
    `${__API_BASE__}/org/${encodeURIComponent(orgId)}/users/bulkImport/guest`,
    'POST',
    formData,
    null,
    false,
    true
  );
};
