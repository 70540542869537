import dot from 'dot-object';
import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { ORG_REGISTRATION_CONTEXT, ORG_REGISTRATION_STATUS, NO_CURRICULUM } from '@oup/shared-node-browser/constants';

import { hearAboutUsValues as hearAboutUsDefaultValues } from '@oup/shared-node-browser/org';
import {
  ErrorStatus,
  PageWrapper,
  PopoutPanelIconHeading,
  LegacyRegisterOrg,
  LegacyRegisterOrgSuccess
} from '../components';
import MyProfileEditPanel from '../panels/MyProfileEditPanel';
import env from '../globals/envSettings';
import { types as popoutPanelIconHeadingTypes } from '../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import { orgRoles, otherEnabledOrgRoles, oupCustomerSupportEnabledOrgRoles } from '../globals/orgRoles';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';
import { closeEditMyAccountModal } from '../redux/actions/hubUi';
import { sanitizeUrl, prepareTargetUrl } from '../utils/url';
import { switchOrg } from '../redux/reducers/identity.reducer';
import userRoles from '../globals/userRoles';
import withDataRecency from '../dataRecency/withDataRecency';
import { isHubMode, isOptMode, getCurrentPlatform, isOteMode } from '../utils/platform';
import { HubLayoutConstants } from '../globals/hubConstants';

class LegacyRegisterOrgPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch: null,
      roles: {},
      role: null,
      hidden: [],
      disabled: []
    };
  }

  async componentWillMount() {
    const {
      location = { pathname: '', search: '' },
      match: { params },
      getPartialOrg,
      validate,
      generateOrgUniqueId,
      userEmail
    } = this.props;
    const queryParams = qs.parse(location.search);
    const { adminUserEmail: email } = queryParams;
    const orgId = params.orgId || queryParams.orgId;

    let state = {};

    if (orgId) {
      state = {
        ...state,
        hidden: ['adminUser.email', 'role'],
        acceptedTermsAndConditions: !isOteMode()
      };

      getPartialOrg(orgId);
    } else {
      generateOrgUniqueId();
      state = {
        ...state,
        acceptedTermsAndConditions: !isOptMode()
      };
    }
    if (email) {
      const adminUserEmail = { 'adminUser.email': email };
      state = {
        ...state,
        ...adminUserEmail
      };
      validate(dot.object(adminUserEmail));
    }

    if (isOteMode()) {
      state = {
        ...state,
        disabled: ['primaryEmail']
      };
    }

    const isOupCustomerSupport = location.pathname.startsWith('/dashboard');

    if (isOptMode()) this.setState({ primaryEmail: userEmail, placementCenter: true });
    await this.setState({
      ...state,
      // Apply form defaults based on initial context
      ...(isOupCustomerSupport
        ? {
            branch: ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT,
            roles: oupCustomerSupportEnabledOrgRoles,
            role: orgRoles.PRIMARY_SCHOOL,
            hidden: [
              'address',
              'city',
              'taxRegistration',
              'province',
              'department',
              'hearAboutUs',
              'acceptedTermsAndConditions',
              'webAddress'
            ]
          }
        : {
            branch: ORG_REGISTRATION_CONTEXT.CES,
            roles: otherEnabledOrgRoles,
            role: orgRoles.SECONDARY_SCHOOL,
            hidden: [
              ...(state.hidden || []),
              'adminUser.email',
              'role',
              'sapNumber',
              'orgRegion',
              'orgPostcode',
              ...(!((isOteMode() && orgId) || isOptMode()) ? ['acceptedTermsAndConditions'] : []),
              ...(isOptMode()
                ? ['webAddress', 'primaryEmail']
                : ['address', 'city', 'taxRegistration', 'province', 'department', 'hearAboutUs', 'webAddress'])
            ]
          })
    });
  }

  /**
   * @deprecated Replace with getDerivedStateFromProps in React 16
   */
  componentWillReceiveProps({ role }) {
    const { role: roleData } = this.props;
    if (roleData !== role) {
      this.setState({ role });
    }
  }

  componentDidUpdate(prevProps) {
    const { orgId, validate, registrationStatus, loading } = this.props;
    const { props } = this;
    if (!orgId) {
      return;
    }

    if (!loading && registrationStatus === ORG_REGISTRATION_STATUS.REGISTERED) {
      this._goToReturnUrl();
    }

    const updates = Object.keys(this._getInput()).reduce(
      (carry, prop) => ({
        ...carry,
        ...(props[prop] !== prevProps[prop] ? { [prop]: props[prop] } : {})
      }),
      {}
    );

    if (!Object.keys(updates).length) {
      return;
    }

    validate(updates);
  }

  _setTermsAndConditions = () => {
    const { acceptedTermsAndConditions } = this.state;
    this.setState({ acceptedTermsAndConditions: !acceptedTermsAndConditions });
  };

  _createChangeHandler = name => async value => {
    await this.setState({ [name]: value });
  };

  _createBlurHandler = name => () => {
    const {
      state,
      props: { validate }
    } = this;
    validate(dot.object(pick(state, name)));
    this.setNoCurriculumForOIC();
    this.setOrgLocationOnlyForUK();
  };

  setNoCurriculumForOIC = async () => {
    const { role, curriculumType } = this.state;
    if (role === orgRoles.OIC_SCHOOL) {
      await this.setState({ curriculumType: NO_CURRICULUM });
    }
    if (role !== orgRoles.OIC_SCHOOL && curriculumType === NO_CURRICULUM) {
      await this.setState({ curriculumType: '' });
    }
  };

  setOrgLocationOnlyForUK = async () => {
    const { countryCode } = this.state;
    if (countryCode !== 'GB') {
      await this.setState({ orgRegion: '', orgPostcode: '' });
    }
  };

  _getInput = () => {
    const { orgId } = this.props;
    const props = [
      ...(!orgId ? ['adminUser.email'] : []),
      'name',
      'role',
      'countryCode',
      'orgRegion',
      'orgPostcode',
      'curriculumType',
      'primaryEmail',
      'placementCenter',
      'webAddress',
      'isLmsLtiTool',
      'province',
      'taxRegistration',
      'sapNumber',
      'department',
      'hearAboutUs',
      'address',
      'city'
    ];
    return dot.object({
      ...pick(this.props, [...props, 'orgUniqueId']),
      ...pick(this.state, props),
      registrationStatus: ORG_REGISTRATION_STATUS.REGISTERED,
      platformCode: getCurrentPlatform()
    });
  };

  _continue = () => {
    const { review } = this.props;
    const { branch } = this.state;
    review(this._getInput(), branch);
  };

  _submit = () => {
    const { register } = this.props;
    const { branch } = this.state;
    register(this._getInput(), branch);
  };

  _goToAddLicence = () => {
    const { orgId, redirect } = this.props;
    redirect(`/dashboard/org/${orgId}/licence`);
  };

  _goBackToDashboard = () => {
    const { redirect } = this.props;
    redirect('/dashboard');
  };

  _goToReturnUrl = () => {
    const { location, orgId, orgSwitch } = this.props;
    const { role } = this.state;
    const redirectTo = prepareTargetUrl();
    const query = new URLSearchParams(location.search);
    const returnUrl = query.get('returnUrl');

    if (redirectTo) {
      window.location = redirectTo;
    } else if (returnUrl) {
      window.location = sanitizeUrl(returnUrl);
    } else if (isHubMode()) {
      orgSwitch(
        orgId,
        userRoles.ORG_ADMIN,
        true,
        `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`
      );
    } else if (role === orgRoles.PRIMARY_SCHOOL) {
      window.location = `${env.integrations.orb.dashboardUrl}`;
    } else {
      orgSwitch(orgId, userRoles.ORG_ADMIN);
    }
  };

  render() {
    const { branch, roles, role, hidden, acceptedTermsAndConditions, disabled } = this.state;
    const {
      localizedContent: { registerOrgPage: content, countryCodes: countryCodesContent },
      orgId,
      errors,
      loading,
      reviewing,
      confirm,
      submitting,
      success,
      failure,
      generateOrgUniqueId,
      clearFailure,
      change,
      userId,
      orgStaffDataRecency = { ids: [] },
      hubEditAccountModalOpen,
      hubCloseEditMyAccountModal
    } = this.props;
    const input = this._getInput();
    const {
      adminUser,
      name,
      countryCode,
      orgRegion,
      orgPostcode,
      curriculumType,
      orgUniqueId,
      primaryEmail,
      webAddress,
      isLmsLtiTool,
      province,
      taxRegistration,
      sapNumber,
      department,
      hearAboutUs,
      address,
      city
    } = input;

    const hearAboutUsValues = hearAboutUsDefaultValues;
    const isOupCustomerSupport = branch === ORG_REGISTRATION_CONTEXT.OUP_CUSTOMER_SUPPORT;
    const syncing =
      !isOupCustomerSupport &&
      !orgStaffDataRecency.synced &&
      orgStaffDataRecency.ids.length &&
      orgStaffDataRecency.ids.includes(userId);

    return (
      <PageWrapper>
        <Helmet title={content.title} />
        {(() => {
          switch (true) {
            case loading:
            case reviewing:
            case submitting:
            case syncing:
              return (
                // @ts-ignore
                <PopoutPanelIconHeading type={popoutPanelIconHeadingTypes.LOADING} title={content.waiting_message} />
              );
            case success:
              return isOptMode() ? (
                this._goToReturnUrl()
              ) : (
                <LegacyRegisterOrgSuccess
                  {...(isOupCustomerSupport
                    ? {
                        primaryButtonLabel: content.add_licence_button,
                        secondaryButtonLabel: content.back_to_dashboard_button,
                        onPrimaryButtonClick: this._goToAddLicence,
                        onSecondaryButtonClick: this._goBackToDashboard
                      }
                    : {
                        primaryButtonLabel: content.go_to_org_button,
                        onPrimaryButtonClick: this._goToReturnUrl
                      })}
                  id={orgId}
                  name={name}
                  branch={branch}
                />
              );
            case failure:
              return (
                // @ts-ignore
                <ErrorStatus
                  title={content.error_title}
                  buttonText={content.error_button}
                  buttonOnClickHandler={clearFailure}
                />
              );
            default:
              return (
                <>
                  <LegacyRegisterOrg
                    roles={roles}
                    countries={countryCodesContent}
                    hearAboutUsValues={hearAboutUsValues}
                    id={orgId}
                    adminUser={adminUser}
                    name={name}
                    role={role}
                    countryCode={countryCode}
                    orgRegion={orgRegion}
                    orgPostcode={orgPostcode}
                    curriculumType={curriculumType}
                    orgUniqueId={orgUniqueId}
                    primaryEmail={primaryEmail}
                    webAddress={webAddress}
                    isLmsLtiTool={isLmsLtiTool}
                    province={province}
                    taxRegistration={taxRegistration}
                    sapNumber={sapNumber}
                    department={department}
                    hearAboutUs={hearAboutUs}
                    city={city}
                    address={address}
                    errors={pick(errors, Object.keys(input))}
                    hidden={hidden}
                    preview={confirm}
                    showSupportLink={!isOupCustomerSupport}
                    createChangeHandler={this._createChangeHandler}
                    createBlurHandler={this._createBlurHandler}
                    generateOrgUniqueId={generateOrgUniqueId}
                    onContinue={this._continue}
                    onChange={change}
                    onSubmit={this._submit}
                    acceptedTermsAndConditions={acceptedTermsAndConditions}
                    setTermsAndConditions={this._setTermsAndConditions}
                    disabled={disabled}
                  />
                  {isHubMode() ? (
                    <MyProfileEditPanel isOpen={hubEditAccountModalOpen} closePanel={hubCloseEditMyAccountModal} />
                  ) : null}
                </>
              );
          }
        })()}
      </PageWrapper>
    );
  }
}

LegacyRegisterOrgPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  registrationStatus: PropTypes.string,
  adminUser: PropTypes.shape({ email: PropTypes.string }),
  name: PropTypes.string.isRequired,
  branch: PropTypes.string,
  role: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  orgRegion: PropTypes.string.isRequired,
  orgPostcode: PropTypes.string.isRequired,
  curriculumType: PropTypes.string.isRequired,
  orgUniqueId: PropTypes.string.isRequired,
  primaryEmail: PropTypes.string.isRequired,
  placementCenter: PropTypes.bool.isRequired,
  department: PropTypes.string.isRequired,
  hearAboutUs: PropTypes.string.isRequired,
  webAddress: PropTypes.string.isRequired,
  province: PropTypes.string.isRequired,
  taxRegistration: PropTypes.string.isRequired,
  sapNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  orgStaffDataRecency: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  reviewing: PropTypes.bool.isRequired,
  confirm: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  failure: PropTypes.bool.isRequired,
  getPartialOrg: PropTypes.func.isRequired,
  generateOrgUniqueId: PropTypes.func.isRequired,
  clearFailure: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  review: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  orgSwitch: PropTypes.func.isRequired,
  hubEditAccountModalOpen: PropTypes.bool,
  hubCloseEditMyAccountModal: PropTypes.func,
  acceptedTermsAndConditions: PropTypes.bool
};

export default compose(
  withRouter,
  withLocalizedContent('registerOrgPage', 'countryCodes'),
  withDataRecency('orgStaff'),
  connect(
    state => ({
      userId: state.identity.userId,
      userEmail: state.identity.email,
      ...pick(state, ['registerOrgPage']),
      ...pick(state.orgRegistration, [
        'orgId',
        'adminUser',
        'registrationStatus',
        'name',
        'role',
        'countryCode',
        'orgRegion',
        'orgPostcode',
        'curriculumType',
        'orgUniqueId',
        'primaryEmail',
        'webAddress',
        'province',
        'city',
        'address',
        'taxRegistration',
        'sapNumber',
        'department',
        'placementCenter',
        'isLmsLtiTool',
        'hearAboutUs',
        'errors',
        'loading',
        'reviewing',
        'confirm',
        'submitting',
        'success',
        'failure'
      ]),
      hubEditAccountModalOpen: state.hubUi?.editAccountModalOpen
    }),
    (dispatch, props) => ({
      getPartialOrg: id => {
        dispatch(actions.getPartialOrgRequest(id));
      },
      generateOrgUniqueId: () => {
        dispatch(actions.generateOrgUniqueIdRequest());
      },
      clearFailure: () => {
        dispatch(actions.registerOrgClearFailure());
      },
      validate: input => {
        dispatch(actions.validateOrgInput(input));
      },
      review: (input, branch) => {
        dispatch(actions.reviewOrgInput(input, branch));
      },
      change: () => {
        dispatch(actions.changeOrgInput());
      },
      register: (input, branch) => {
        dispatch(actions.registerOrgRequest(input, branch));
      },
      redirect: path => {
        dispatch(actions.registerOrgReset());
        props.history.push(path);
      },
      orgSwitch: (orgId, role, returnBack, returnUrl) => {
        dispatch(switchOrg(orgId, role, returnBack, returnUrl));
      },
      hubCloseEditMyAccountModal: () => {
        dispatch(closeEditMyAccountModal());
      }
    })
  )
)(withRouter(LegacyRegisterOrgPage));
